import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"
import LatestPosts from "../components/Post/LatestPosts"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Impulsa tu negocio"
          subTitle="¡Te ayudamos a llevar tu negocio a internet! En pocos dias y sin conocimientos técnicos empieza a aceptar pagos con tarjetas de credito en linea."
        />
        <BasicTextModule
          title="Tenemos un plan adecuado para ti ¡desde un pago de $990 de por vida!"
          content="Con un único pago podras comenzar a recibir ganancias atravez de internet, ademas los planes de suscripción te permitiran actualizar tu contenido con mas frecuencia para tus clientes."
          link="/products"
          linkText="Ver Productos"
        />
        <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule>
        <Features
          title="Conoce nuestros productos"
          introduction="Revisa todas las ventajas que nuestros planes te ofrecen, todos los pagos que recibas en la plataforma se veran reflajados en tu cuenta bancaria en un lapzo de 24 hrs."
        />
        {/* <LatestPosts
          title="The Latest from Barcadia"
          introduction="Cras scelerisque, tellus sed gravida tincidunt, velit tellus blandit justo, nec viverra augue erat in erat. Maecenas iaculis sed purus non fringilla."
        /> */}
      </Layout>
    </>
  )
}

export default Index
